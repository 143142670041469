* {
  font-family: var(--main-font);
}

.Team {
  font-family: "Poppins", sans-serif;
  padding: 0 20px;
  text-align: center;
  font-size: 28px;
  overflow: hidden;
  margin-top: 50PX;
}

.maintainer_title {  
  font-size: 20px;
  margin: 0.5rem 0;
}

.logo_div {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;  
}

.github_icon {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.github_icon:hover {
  transform: scale(1.2);
}

.linkedin_icon {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.linkedin_icon:hover {
  transform: scale(1.2);
}

.titleMain {
  font-size: 2rem;
  margin: 2rem;
  font-weight: bold;
  text-shadow: 3px 4px 2px #0000002e;
}

.projectMaintainers {
  display: flex;
  justify-content: center;
  gap: 45px;
  flex-wrap: wrap;
}

.team_container {
  text-align: -webkit-center;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 4vw;
  gap: 2rem;
}

.figure {
  box-shadow: 0 4px 8px 0 #444, 0 6px 20px 0 #444;
  width: 7%;
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
}

.figure > img {
  -webkit-filter: contrast(125%) brightness(100%) grayscale(100%);
  -moz-filter: contrast(125%) brightness(100%) grayscale(100%);
  -o-filter: contrast(125%) brightness(100%) grayscale(100%);
  -ms-filter: contrast(125%) brightness(100%) grayscale(100%);
  filter: contrast(125%) brightness(100%) grayscale(100%);
  -ms-transition: filter 0.3s ease-in-out;
  -o-transition: filter 0.3s ease-in-out;
  -moz-transition: filter 0.3s ease-in-out;
  -webkit-transition: filter 0.3s ease-in-out;
  transition: filter 0.3s ease-in-out;
}

.figure > figcaption {
  bottom: -100%;
  opacity: 0;
  background: rgba(245, 160, 30, 0.7);
  text-shadow: 0 0 1px #000;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.figure > .fig_caption_text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 0.7rem !important;
}

.figure > .fig_caption_text:first-child {
  font-size: 120%;
  text-transform: uppercase;
}

.figure > .fig_caption_text:last-child {
  font-size: 90%;
  line-height: 110%;
}

.figure:hover > img {
  filter: contrast(125%) brightness(100%) grayscale(0);
  -webkit-filter: contrast(125%) brightness(100%) grayscale(0);
  -moz-filter: contrast(125%) brightness(100%) grayscale(0);
  -o-filter: contrast(125%) brightness(100%) grayscale(0);
  -ms-filter: contrast(125%) brightness(100%) grayscale(0);
}

.figure:hover > figcaption {
  bottom: 0;
  opacity: 1;
}

/* Profile Card */

.position_relative {
  position: relative;
  border-radius: 50%;
}

.position_absolute {
  position: absolute;
}

.display_inline_block {
  display: inline-block;
}

.grid_wd_100 {
  width: 100%;
}

.profile_img{
  height: 100%;
}

.text_center {
  text-align: center;
}

.va_top {
  vertical-align: top;
}

.pad {
  padding: 1em !important;
}
