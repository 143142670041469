* {
    font-family: var(--main-font);
}

.main-heading{
    text-align: center;
    margin-bottom: 50px;
    font-size: 1.5rem;
}
.main-div{
    margin-top: 60px;
    padding: 5% 5%;
}

.form{
    height: auto;
    min-height: 53vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group{
    margin-bottom: 10px;
    width: 100%;
}

.submit-div{
    width: fit-content;
    border: 1px solid red;
    text-align: center;
    padding: 4px 5px;
    border-radius: 4px;
    background-color: red;
}

.submit-button{
    background-color: red;
    padding: 5px 8px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.details-button{
    background-color: blue;
    padding: 5px 8px;
    border-radius: 5px;
    margin-bottom: 10px;
}

#name , #instructions , #img-link {
    width: 100%;
}