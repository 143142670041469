.list-container{
    background: var(--navbar-color1);
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 10px;
    font-size: 1.3rem;
}

.list{
    list-style: none;
    width: 100%;
    background: var(--navbar-color1);
    display: flex;
    flex-direction: column;
}

.listItem{
    border-bottom: 2px solid transparent;
    font-family: var(--main-font);
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.link{
    text-decoration: none;
    color: var(--navbar-color2);
    font-family: var(--main-font);
}
.link:hover{
    border-bottom: 2px solid #22a6b3;
    transition: all 0.2s ease-in-out;
}