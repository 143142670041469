.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-img {
    height: 500px;
    width: 500px;
}

.error-cheese {
    position: absolute;
    top: 25%;
    left: 49%;
    width: 150px;
    height: 150px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


input[type=button] {
    border-radius: 25px;
    background-color: #fdd835;
    border: none;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 16px;
    width: 200px;
    height: 50px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 36px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
}

input:hover {
    padding-left: 2px;
}