img {
    width: 100%;
    height: 310px;
    object-fit: cover;
  
   
    
  }
  
  .frame {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 90px;
  }
  
  .dishes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 50px;
    padding: 0% 10%;
  }
  
  .dish {
    width: 30%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    box-shadow: 11px 14px 47px -8px rgba(0, 0, 0, 0.78);
    align-items: center;
    background:radial-gradient(#111 50%,#000 100%) ;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-out;
  }
  
  .dish img{
      transform: scale(1.25);
      transition: all 0.3s ease-out;
  }
  
  .dish:hover img{
      transform: scale(1.1) translateY(-10px);
      opacity: 0.4;
  }
  
  .details{
     position: absolute;
     left: 0;
     bottom: 10%;
      width: 100%;
      padding: 10px;
      text-align: center;
      color: #fff;
      gap: 10px;
  
  }
  
  .click-button{
      background-color: black;
      color: white;
      padding: 10px 16px;
      border: none;
      outline: none;
      border-radius: 5px;
      margin-top: 20px;
      transform: translateY(35px);
      opacity: 0;
  }
  
  .details h3{
      font-size: 30px;
      font-weight: bold;
      transform: translateY(35px);
      opacity: 0;
  }
  
  a{
      text-decoration: none;
      color: white;
  }
  
  .dish:hover .details h3,.dish:hover .details button{
      opacity: 1;
      transform: translateY(0px);
  
  }
  
  .dish:hover .details h3{
      transition: all 0.3s ease-out 0.1s;
  }
  
  .dish:hover .details button{
      transition: all 0.3s ease-out 0.2s;
  }

  @media screen and (max-width: 1200px){
      .dish{
          width: 40%;
      }
  }
  
  @media screen and (max-width: 1000px){
      .dish{
          width: 44%;
      }
  }
  @media screen and (max-width: 800px){
      .dish{
          width: 63%;
      }
  }
  @media screen and (max-width: 500px){
      .dish{
          width: 80%;
      }
  }